<template>
  <b-card>

    <question-add-content :event-id="eventId" :event-booth-id="eventBoothId" :isEdit="true" class="mt-2 pt-75" />
  </b-card>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner
} from 'bootstrap-vue'
import QuestionAddContent from './QuestionAddContent.vue'
import router from '@/router'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,
    QuestionAddContent,
  },
  data() {
    return {
      eventId: router.currentRoute.params.event_id,
      eventBoothId: router.currentRoute.params.event_booth_id
    }
  },
}
</script>